import { useLocation } from '@remix-run/react';
import { useCallback } from 'react';

import { useQueryParam } from '../../hooks/useQueryParam';
import { type UserAuthenticatedResponse } from '../../services/api-service';
import {
  type KnownRedirectParams,
  paramsWithRedirectTo,
} from '../../utils/redirect-to';
import { useUserContext } from '../UserContext';
import { type LoginSuccessCallback } from './types';

export const usePostLogin = (): LoginSuccessCallback => {
  const { postLogin } = useUserContext();

  return useCallback(
    (resp: UserAuthenticatedResponse) => {
      postLogin(resp.token, resp.user);
    },
    [postLogin]
  );
};

export function useRedirectTo(): string | null {
  return useQueryParam('redirect-to');
}

export function buildSearchParamsWithRedirectToAsString(
  to: Nullable<string>,
  extra?: Partial<KnownRedirectParams>
): string {
  const params = buildSearchParamsWithRedirectTo(to, extra);
  return `?${params.toString()}`;
}

export function buildSearchParamsWithRedirectTo(
  to: Nullable<string>,
  extra?: Partial<KnownRedirectParams>
): URLSearchParams {
  const params = paramsWithRedirectTo(to);
  if (extra) {
    for (const [key, val] of Object.entries(extra)) {
      if (val) {
        params.set(key, `${val}`);
      }
    }
  }
  return params;
}

export class AuthUtils {
  static FromPlayableLink(from?: string | null): boolean {
    if (!from) return false;
    try {
      const url = new URL(from);
      for (const regex of [
        /\/venue/i, // venue link
        /\/events\/[\w-]{36}\/play/i, // event link
      ]) {
        if (regex.test(url.pathname)) {
          return true;
        }
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}

export interface LoginState {
  error?: string;
}

export function useLoginState() {
  const location = useLocation();
  const state = location.state;

  const result: LoginState = {};
  if (!state || typeof state !== 'object') return result;
  if (typeof state.error === 'string') {
    result['error'] = state.error;
  }
  return result;
}

import { useNavigate } from '@remix-run/react';
import React, {
  type ReactNode,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
} from 'react';
import { proxy, snapshot } from 'valtio';

import {
  type DtoDerivedVenueSettings,
  type DtoDeriveVenueSettingsRequest,
} from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import {
  apiService,
  type UpdateVenueRequest,
} from '../../services/api-service';
import { type Venue, type VenueOwner } from '../../types';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../utils/valtio';
import { useUser } from '../UserContext';
import { venueLogger } from './shared';

type State = {
  venue: Venue | null;
  derivedVenueSettings: DtoDerivedVenueSettings | null;
  myVenue: Venue | null;
  venueOwner: VenueOwner | null;
  registrationOrgId: string | null;
};

type Dependencies = {
  openUrl?: (url: string) => void;
};

class VenueAPI {
  private _state = markSnapshottable(proxy(this.initialState()));
  private _deps: Dependencies = {};

  constructor(readonly log = venueLogger) {}

  get state(): Readonly<ValtioSnapshottable<State>> {
    return this._state;
  }

  async init(venueId?: string) {
    const venueResp = venueId
      ? apiService.venue.getVenueById(venueId)
      : apiService.venue.getMyVenue();
    const venue = (await venueResp).data.venue;
    const id = venue?.id;
    if (!id) throw new Error('venue id is missing');
    this._state.venue = venue;
    this.log.debug('init venue from API', { venue });

    const [ownerResp, eventResp] = await Promise.all([
      apiService.venue.getVenueOwnerById(id),
      venue.eventId
        ? apiService.event.getPublicEvent(venue.eventId)
        : Promise.resolve(null),
    ]);

    this._state.venueOwner = ownerResp.data.owner;
    // returns the id of the org that should be used for a user auth registration flow.
    const venueEvent = eventResp?.data.event ?? null;
    this._state.registrationOrgId =
      this._state.venueOwner?.orgId ?? venueEvent?.orgId ?? null;
  }

  async initMyVenue() {
    const resp = await apiService.venue.getMyVenue();
    this._state.myVenue = resp.data.venue;
  }

  updateVenue(data: Partial<Venue>) {
    if (!this._state.venue) return;
    ValtioUtils.update(this._state.venue, data);
  }

  updateMyVenue(data: Partial<Venue>) {
    if (!this._state.myVenue) return;
    ValtioUtils.update(this._state.myVenue, data);
  }

  updateDerivedVenueSettings(data: DtoDerivedVenueSettings | null) {
    ValtioUtils.set(this._state, 'derivedVenueSettings', data);
  }

  async deriveVenueSettings(req: DtoDeriveVenueSettingsRequest) {
    if (!this._state.venue?.id) return;
    this._state.derivedVenueSettings = null;
    const settings = await apiService.venue.deriveSettings(
      this._state.venue.id,
      req
    );
    this._state.derivedVenueSettings = settings.data;
  }

  async persist(req: UpdateVenueRequest) {
    const resp = await apiService.venue.updateVenue(req);
    this.updateMyVenue(resp.data.venue);
    if (this._state.myVenue?.id === this._state.venue?.id) {
      this.updateVenue(resp.data.venue);
      this.log.debug('update venue from settings', { venue: resp.data.venue });
    }
  }

  snapshot() {
    return snapshot(this.state);
  }

  syncDeps(deps: Partial<Dependencies>) {
    this._deps = { ...this._deps, ...deps };
  }

  get deps(): Readonly<Dependencies> {
    return this._deps;
  }

  private initialState(): State {
    return {
      venue: null,
      derivedVenueSettings: null,
      myVenue: null,
      venueOwner: null,
      registrationOrgId: null,
    };
  }
}

type Context = {
  api: VenueAPI;
};

const context = React.createContext<Context | null>(null);

function useVenueContext() {
  const ctx = useContext(context);
  if (!ctx) throw new Error('VenueContext is not in the tree!');
  return ctx;
}

export function useVenueAPI() {
  return useVenueContext().api;
}

export function useVenueUrlOpener() {
  return useVenueContext().api.deps.openUrl;
}

function useLoadedVenue() {
  const api = useVenueAPI();
  const venue = useSnapshot(api.state).venue;
  if (!venue) throw new Error('venue is not initialized');
  return venue as Venue; // valtio snapshot readonly workaround
}

export function useVenue(): [Venue, (data: Partial<Venue>) => void] {
  const venue = useLoadedVenue();
  const api = useVenueAPI();
  const updateVenue = useMemo(() => api.updateVenue.bind(api), [api]);
  return [venue, updateVenue];
}

export function useVenueDerivedSettings() {
  const api = useVenueAPI();
  return useSnapshot(api.state).derivedVenueSettings;
}

export function useVenueRegistrationOrgId() {
  const api = useVenueAPI();
  return useSnapshot(api.state).registrationOrgId;
}

export function useVenueOwnerGetter() {
  const api = useVenueAPI();
  return useCallback(() => {
    return api.state.venueOwner;
  }, [api]);
}

export function useNavigateToVenueAtCapacity() {
  const navigate = useNavigate();
  const venueId = useVenueId();
  return useLiveCallback(() => {
    navigate(`/venue/${venueId}/at-capacity`);
  });
}

export function useMyVenue() {
  const api = useVenueAPI();
  return (useSnapshot(api.state) as typeof api.state).myVenue;
}

export function useVenueOwner() {
  const api = useVenueAPI();
  const venueOwner = (useSnapshot(api.state) as typeof api.state).venueOwner;
  if (!venueOwner) throw new Error('venueOwner is not initialized');
  return venueOwner;
}

export function useVenueId() {
  const venue = useLoadedVenue();
  return venue.id;
}

export function getVenueSlug(
  venueLike: Venue | { venueId: string; venueName?: string | null }
) {
  if ('venueId' in venueLike) {
    return venueLike.venueName || venueLike.venueId;
  }
  return venueLike.name || venueLike.id;
}

export function useVenueSlug() {
  const venue = useLoadedVenue();
  return getVenueSlug(venue);
}

export const VenueContextProvider = (props: {
  paramVenueId?: string;
  children?: ((venueId: string) => JSX.Element) | ReactNode;
  fallback?: ReactNode | null;
}): JSX.Element | null => {
  const { paramVenueId } = props;
  const { venueActivated } = useUser();

  const api = useInstance(() => new VenueAPI());

  useLayoutEffect(() => {
    api.init(paramVenueId);
  }, [api, paramVenueId]);

  useLayoutEffect(() => {
    if (!venueActivated) return;
    api.initMyVenue();
  }, [api, venueActivated]);

  const ctxValue: Context = useMemo(() => ({ api }), [api]);
  const venue = useSnapshot(api.state).venue;
  const venueOwner = useSnapshot(api.state).venueOwner;
  const ready = !!venue && !!venueOwner;

  if (!ready) return <>{props.fallback}</>;
  return (
    <context.Provider value={ctxValue}>
      {typeof props.children === 'function'
        ? props.children(venue.id)
        : props.children}
    </context.Provider>
  );
};
